<template>
  <div class="site">
    <section class="site-content section p-5" :style="$parent.mobileBackground">
      <section class="section pb-5 is-flex is-flex-direction-column is-justify-content-space-between">
        <app-logo />

        <div class="card">
          <div class="card-content has-text-centered">
            <p class="has-text-weight-bold pb-2">{{$t('system.inactivityTitle')}}</p>
            <p class="is-size-7">{{$t('system.inactivityDesc')}}</p>
          </div>
          <footer class="card-footer is-justify-content-center p-4">
            <b-button
            type="is-primary"
            size="is-small"
            :label="$t('system.inactivityButton')"
            @click="Reload()"
            expanded/>
          </footer>
        </div>

      </section>
    </section>
  </div>
</template>

<script scoped>
  export default {
    data() {
      return {};
    },
    methods: {
      Reload() {
        window.location.href="/";
      },
    },
    mounted(){
      console.log("Inactivity mounted")
      // Closing socket connection
      this.$store.commit("SetdisableReconnectingAlert", true);
      this.$socket.client.close();
    }
  };
</script>
  

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.route_duplicate {
  background-color: $system-screen-background;

  .site-content > section {

    height: 100%

  }

  .card {
    @include blurBox();
  }
}
</style>